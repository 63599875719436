@font-face {
  font-family: Suisse Int\'l Mono;
  src: url("suisseintlmono-regular-webfont.9e7ef2c6.woff2") format("woff2"), url("suisseintlmono-regular-webfont.37a401a7.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Dada Grotesk;
  src: url("DadaGrotesk-Heavy.94c29651.woff2") format("woff2"), url("DadaGrotesk-Heavy.d1633874.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Dada Grotesk;
  src: url("DadaGrotesk-HeavyIta.1e52f513.woff2") format("woff2"), url("DadaGrotesk-HeavyIta.0f234175.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: block;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  --sbw: 0px;
}

* {
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
}

::selection {
  color: inherit;
  background: inherit;
}

html {
  font-variant-ligatures: none;
  font-size: .661376vw;
  font-size: calc(.661376vw - var(--sbw) / 151.2);
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-family: Dada Grotesk;
}

html.no-scroll {
  overscroll-behavior: none;
  overflow-y: scroll;
}

@media screen and (max-width: 784px) {
  html.no-scroll {
    overflow: hidden;
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 1.27551vw;
    font-size: calc(1.27551vw - var(--sbw) / 78.4);
  }
}

@media screen and (max-width: 784px) {
  html {
    font-size: 2.33645vw;
    font-size: calc(2.33645vw - var(--sbw) / 42.8);
  }
}

.text {
  text-transform: uppercase;
  letter-spacing: .016rem;
  font-size: 1.3rem;
  font-weight: 900;
  line-height: 1.686rem;
}

@media screen and (max-width: 784px) {
  .text {
    font-size: 1.4rem;
    line-height: 1.816rem;
  }
}

/*# sourceMappingURL=index.14afafbe.css.map */
