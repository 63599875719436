.text {
  font-weight: 900;
  font-size: 1.3rem;
  line-height: 1.686rem;
  text-transform: uppercase;
  letter-spacing: 0.016rem;

  @include mobile {
    font-size: 1.4rem;
    line-height: 1.816rem;
  }
}