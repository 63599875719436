@font-face {
  font-family: "Suisse Int'l Mono";
  src: url("/src/assets/fonts/suisseintlmono-regular-webfont.woff2") format("woff2"),
  url("/src/assets/fonts/suisseintlmono-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Dada Grotesk";
  src: url("/src/assets/fonts/DadaGrotesk-Heavy.woff2") format("woff2"),
  url("/src/assets/fonts/DadaGrotesk-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Dada Grotesk";
  src: url("/src/assets/fonts/DadaGrotesk-HeavyIta.woff2") format("woff2"),
  url("/src/assets/fonts/DadaGrotesk-HeavyIta.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: block;
}