// Color
$black: #000;
$white: #fff;

// Responsive
$mobile-mockup-width: 428;
$tablet-mockup-width: 784;
$desktop-mockup-width: 1512;


$tablet-responsive: 1280px;
$tablet-responsive-min: 1281px;

$mobile-responsive: 784px;

// Font family
$font-mono: 'Dada Grotesk';