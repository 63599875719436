@import "styles/fonts";
@import "styles/utilities/reset";
@import "styles/utilities/variables";
@import "styles/utilities/mixin";

:root {
  --sbw: 0px;
}

// GENERAL
* {
  -webkit-tap-highlight-color: rgba($black, 0);
  -webkit-tap-highlight-color: transparent;

  &::selection {
    color: inherit;
    background: inherit;
  }
}

html {
  font-variant-ligatures: none;
  font-size: #{1000 / $desktop-mockup-width}vw;
  font-size: calc(#{1000 / $desktop-mockup-width}vw - var(--sbw) / #{$desktop-mockup-width / 10});

  height: 100%;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;

  &.no-scroll {
    overflow-y: scroll;
    overscroll-behavior: none;

    @include mobile {
      overflow: hidden;
    }
  }

  @include tablet {
    font-size: #{1000 / $tablet-mockup-width}vw;
    font-size: calc(#{1000 / $tablet-mockup-width}vw - var(--sbw) / #{$tablet-mockup-width / 10});
  }

  @include mobile {
    font-size: #{1000 / $mobile-mockup-width}vw;
    font-size: calc(#{1000 / $mobile-mockup-width}vw - var(--sbw) / #{$mobile-mockup-width / 10});
  }

  font-family: $font-mono;
}

@import "styles/components/text";