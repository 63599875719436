// Calc vw func
@function vw($size, $width: 1680, $responsive: false) {
	$size: if($responsive, $size * $responsive, $size);
	$vw: round-dec(($size / $width) * 100, 2);

	@return #{$vw}vw;
}

@function round-dec($number, $digits: 0) {
	$n: 1;

	@if type-of($number) != number {
		@return $number;
	}

	@if $digits > 0 {
		@for $i from 1 through $digits {
			$n: $n * 10;
		}
	}

	@return round($number * $n) / $n;
}

// Media queries
@mixin desktop {
	@media screen and (min-width: #{$desktop-mockup-width}px) {
		@content;
	}
}

@mixin tablet {
	@media screen and (max-width: $tablet-responsive) {
		@content;
	}
}

@mixin mobile {
	@media screen and (max-width: $mobile-responsive) {
		@content;
	}
}
